import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from '../i18n/useTranslation';
import { A } from './common/A';
import { Box } from './common/Box';
import { Grid } from './common/Grid';
import { Text } from './common/Text';

export type ContactCardProps = {
  name: string | null | undefined;
  title: string | null | undefined;
  email: string | null | undefined;
  picture: string | null | undefined;
};

export const ContactCard: React.FC<ContactCardProps> = props => {
  const t = useTranslation();

  const image: IGatsbyImageData = {
    layout: 'fullWidth',
    height: 100,
    width: 100,
    images: {
      sources: [
        {
          type: '',
          sizes: '',
          srcSet: props.picture ?? '',
        },
      ],
    },
  };

  return (
    <Grid gridTemplateColumns="100px 1fr" gridGap={3} alignItems="center">
      <Box width={[100]} height={[100]} borderRadius="50%" overflow="hidden">
        <GatsbyImage image={image as any} alt="contact picture" />
      </Box>
      <Box>
        <Text fontWeight={500}>{props.name}</Text>
        <Text fontSize={1}>{props.title}</Text>
        {props.email && (
          <A href={`mailto:${props.email}`} fontSize={1}>
            {t('emailMe')}
          </A>
        )}
      </Box>
    </Grid>
  );
};
