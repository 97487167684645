import React, { ReactChildren, useMemo } from 'react';
import rehype2react from 'rehype-react';
import { CSSProperties } from 'styled-components';
import unified from 'unified';
import { Text } from './Text';

const ASTText: React.FC = props => {
  return (
    <Text
      {...props}
      as="p"
      fontWeight={300}
      color="#292929"
      fontSize={[2, 3]}
      mb={3}
      maxWidth={900}
    />
  );
};

const ASTLi: React.FC = props => {
  return (
    <Text
      as="li"
      {...props}
      fontWeight={300}
      color="#292929"
      fontSize={[2, 3]}
      mb={2}
      maxWidth={900}
    />
  );
};

type ASTImgProps = {
  src: string;
  style: CSSProperties;
  children: ReactChildren;
};

const ASTImg: React.FC<ASTImgProps> = props => {
  return <img {...props} style={{ maxWidth: '100%', ...props.style }} />;
};

export const ASTRenderer: React.FC<{ ast: any }> = ({ ast }) => {
  const rendered = useRenderedAst(ast);
  return <div style={{paddingBottom: "64px"}}>{rendered}</div>;
};

const renderer = unified().use(rehype2react, {
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
    p: ASTText,
    li: ASTLi,
    img: ASTImg,
  },
});

const useRenderedAst = (ast: any): string => {
  const rendered = useMemo(() => renderer.stringify(ast), [ast]);
  return rendered;
};
