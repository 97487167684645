import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { isIE } from 'react-device-detect';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { ASTRenderer } from '../components/common/ASTRenderer';
import { Box } from '../components/common/Box';
import { Button } from '../components/common/Button';
import { Grid } from '../components/common/Grid';
import { H1 } from '../components/common/Heading';
import { InnerGrid } from '../components/common/InnerGrid';
import { Text } from '../components/common/Text';
import { ContactCard } from '../components/ContactCard';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { useThemeContext } from '../components/ThemeChanger';
import { Trans } from '../i18n/Trans';
import { bodyPadding, grid } from '../theme/theme';
import { PageProps } from '../utils/PageProps';
import { JobQuery } from '../__generated__/graphql';

const JobPage: React.FC<PageProps<JobQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('light');
  }, []);
  const job = data.job!;

  const heading = job.title;

  return notSupportedBrowser ? (
    <Layout locale={pageContext.locale} location={location} alternativeLinks={[]}>
      <Seo title={heading ?? ''} location={location} />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <Layout locale={pageContext.locale} location={location} alternativeLinks={[]}>
      <Seo title={heading ?? ''} location={location} />
      <Grid gridTemplateColumns={grid}>
        <InnerGrid px={bodyPadding} columns={6}>
          <Box
            mb={[4, 4]}
            mt={[3, 4]}
            px={bodyPadding}
            maxWidth={800}
            gridColumn={['1 / -1', '2 / -2']}
          >
            <H1 fontSize={5} lineHeight={1.3} mb={2}>
              {heading}
            </H1>
            <Text fontWeight={500}>{job.locations?.map(l => l?.city).join(', ')}</Text>
          </Box>

          <Box gridColumn={['1 / -1', '2 / -2']} px={bodyPadding} mb={[5, 6]} maxWidth={800}>
            <Box mb={4}>
              <Button
                as="a"
                href={job.links?.careersite_job_apply_url ?? ''}
                target="_blank"
                rel="noreferer noopener"
              >
                <Trans name="applyForJob" />
              </Button>
            </Box>
            <ASTRenderer ast={job.bodyAst} />
            <ContactCard
              name={job.user?.name}
              title={job.user?.title}
              email={job.user?.hide_email ? '' : job.user?.email}
              picture={job.user?.picture?.standard}
            />
            <Box mt={[4, 5]}>
              <Button
                as="a"
                href={job.links?.careersite_job_apply_url ?? ''}
                target="_blank"
                rel="noreferer noopener"
              >
                <Trans name="applyForJob" />
              </Button>
            </Box>
          </Box>
        </InnerGrid>
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query JobQuery($id: String!) {
    job: teamtailorJob(id: { eq: $id }) {
      title
      bodyAst
      links {
        careersite_job_apply_url
      }
      locations {
        city
      }
      user {
        title
        name
        email
        hide_email
        picture {
          standard
        }
      }
    }
  }
`;

export default JobPage;
